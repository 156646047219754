@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.categoryCarousel {
    --slide-size: 80%;
    width: calc(100% + var(--bs-gutter-x));
    height: 100%;
    position: relative;
    margin: 0 calc(var(--bs-gutter-x) * -0.5);

    @media ($smUp) {
        --slide-size: 60%;
    }

    @media ($mdUp) {
        --slide-size: 40%;
    }

    @media ($lgUp) {
        --slide-size: 30%;
    }

    @media ($xxlUp) {
        --slide-size: 20%;
    }
}

.categoryCarouselViewport {
    overflow: hidden;
    height: 100%;
    padding: 0 calc(var(--bs-gutter-x) * 0.5);
    &:before {
        content: ' ';
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: var(--bs-gutter-x);
        background: linear-gradient(to left, rgba(255, 255, 255, 0), $grey-ultra-light);
        z-index: 1;
    }
    &:after {
        content: ' ';
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: var(--bs-gutter-x);
        background: linear-gradient(to right, rgba(255, 255, 255, 0), $grey-ultra-light);
        z-index: 1;
    }
}

.categoryCarouselContainer {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    flex-direction: row;
    height: 100%;
    gap: 1rem;
}

.categoryCarouselSlide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    position: relative;
}

.categoryCarouselButtons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
    pointer-events: none;

    > button {
        opacity: 0;
        padding: .3625rem .7375rem;
        border-radius: 50%;
        pointer-events: auto;
        transform-origin: 50%;
        transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out,transform .2s ease-in-out,opacity .2s ease-in-out;

        // HOVERs
        &:not(:hover) {
            background-color: rgba($grey-ultra-light, 0.8);
        }
        &:hover {
            border-color: $grey-ultra-light;
            background-color: $grey-ultra-light;
            transform: scale(1.125);
        }
        .categoryCarousel:hover &,
        &:focus {
            opacity: 1;
        }

        // STATEs
        &:disabled {
            visibility: hidden;
        }
    }
}
