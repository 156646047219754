@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.hideTabletDesktop {
    @media ($smUp) {
        display: none;
    }
}

.hideMobile {
    @media ($smDown) {
        display: none;
    }
}
